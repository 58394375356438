import * as React from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { getIsValidUser, logoutUser } from "../service/autService";
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { Button } from 'react-bootstrap';


const loginUrl = require("../config").get(process.env.NODE_ENV).loginUrl;

const logout = () => {
  //alert(loginUrl);

  logoutUser().then(res=> {
    window.history.pushState("","",loginUrl);
  window.history.pushState("","",loginUrl);
  window.history.pushState("","",loginUrl);
  window.history.pushState("","",loginUrl);
  window.history.pushState("","",loginUrl);
  window.history.pushState("","",loginUrl);
  window.history.pushState("","",loginUrl);
  window.history.pushState("","",loginUrl);
  window.history.pushState("","",loginUrl);
  window.history.pushState("","",loginUrl);
  window.location.reload();

  setTimeout(() => {
    alert("1111111111");
    window.location.reload();
  }, 5000);
  })

  


  //window.open(loginUrl,"_blank")
  //window.close()

    //window.location.reload();
}

export default class NavMenu extends React.PureComponent<{}, { isOpen: boolean, isAdmin: boolean }> {
  public state = {
    isOpen: false,
    isAdmin: false
  };


  public componentDidMount() {
    this.validateUser();
  }



  

  public render() {

    return (
      <header>
         {this.state.isAdmin && <div><img height="48px"  src="https://rates.ceylonexchange.co.nz/static/logo.jpg" />
        </div>
  }
       
        <Navbar
          className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3"
          light
        >
          <Container>
            <NavbarBrand style={{marginLeft:-16}} tag={Link} to="/">
              {this.state.isAdmin && "Ceylon Exchange NZ - Daily Exchange Rate."}
              {!this.state.isAdmin && "Ceylon Exchange NZ - Daily Exchange Rate.."}
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} className="mr-2" />
            <Collapse style={{marginRight : 16}}
              className="d-sm-inline-flex flex-sm-row-reverse"
              isOpen={this.state.isOpen}
              navbar
            >
              {this.state.isAdmin &&
                <Button variant="outline-warning" style={{ marginLeft : 4, marginRight: 2, height: 40 }}
                onClick={logout}><i className='fas fa-sign-out-alt'>Sign out</i></Button>
                // <a href={loginUrl} target="blank" onClick='javascript: setTimeout(window.close, 10);'>Logout</a>
              }
              {this.state.isAdmin &&
                <ul className="navbar-nav flex-grow">
                  {/* <NavItem>
                    <NavLink tag={Link} className="text-dark" to="/group">
                      Product Group
                      </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink tag={Link} className="text-dark font-weight-bold bg-light" to="/">
                      Product
                      </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} className="text-dark  font-weight-bold bg-light" to="/productcharges">
                      Fees
                      </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} className="text-dark  font-weight-bold bg-light" to="/report">
                      Report Admin
                      </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link} className="text-dark  font-weight-bold bg-light" to="/reportview?view=true">
                      Report
                      </NavLink>
                  </NavItem>

                </ul>
              }
              
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }

  private validateUser = () => {

    getIsValidUser().then(res => {
      if (res.data === true) {
        this.setState({
          isAdmin: true
        });
      }
      else {
        this.setState({
          isAdmin: false
        });
      }
    }).catch(err => {
      this.setState({
        isAdmin: false

      });
      alert(err)
      window.location.replace(loginUrl)
    });

  }

  private toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
}
